<template>

        <b-navbar  class="nav w-100 p-4">
            <b-navbar-brand  class="mx-3" style="font-family: Good Times;cursor: pointer">VR ESCAPE ROOM</b-navbar-brand>


        </b-navbar>

</template>

<script>

    export default {
        name: "navBar",

    }
</script>

<style scoped>
    .nav{
        background-color: #222831;
    }
    .navbar-light .navbar-brand ,.navbar-light .navbar-nav .nav-link{
        color: #eeeeee!important;
        background-color: inherit;
        font-size: 22px;
        margin-left:10%;
        text-align: center;
    }




</style>