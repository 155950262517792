<template>
  <nav class="menu" tabindex="0">
    <div class="smartphone-menu-trigger"></div>
    <ul class="pt-4">
      <li tabindex="0" class="icon-dashboard" @click="$router.push({name:'Clients'})"><span>Clients</span></li>
      <li tabindex="0" class="icon-customers" @click="$router.push({name:'Parties'})"><span>Parties</span></li>
      <li tabindex="0" class="icon-customers" @click="$router.push({name:'Devices'})"><span>Appareils</span></li>
      <li tabindex="0" class="icon-users" @click="$router.push({name:'Payments'})"><span>Paiements</span></li>
    </ul>
  </nav>

</template>

<script>
export default {
  name: "sidebar"
}
</script>

<style scoped >
.menu {
  background: #222831;
  min-height: 88.5vh;
  height: 100%;
  z-index: 5;
  outline: none;
}



ul {
  list-style: none;
  padding: 0.5em 0;
  margin: 0;
}

li {
  padding: 0.5em 1em 0.5em 2em;
  font-size: 1em;
  font-weight: bold;
  background-color: inherit;
  background-repeat: no-repeat;
  background-position: left 15px center;
  background-size: auto 20px;
  transition: all 0.15s linear;
  cursor: pointer;
}

li:hover{
  background-color:rgba(0,0,0,0.1);
}

li:focus{
  outline:none;
}
span{

  color: white;
}
@media screen and (max-width:900px){
  body{
    padding-left:0;
  }

  .menu {
    width: 230px;
    height: auto;
    position: absolute;
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0);
    transform: translate3d(-230px, 0, 0);
    transition: all 0.3s ease-in-out;
  }
  .smartphone-menu-trigger {
    width: 40px;
    height: 40px;
    position: absolute;
    top:0;
    left: 100%;

    background: #222831;
  }

  .smartphone-menu-trigger:before, .smartphone-menu-trigger:after{
    content:'';
    width:50%;
    height:2px;
    background:#eee;
    border-radius:10px;
    position:absolute;
    top:45%;
    left:50%;
    transform:translate3d(-50%, -50%, 0);
  }

  .menu.smartphone-menu-trigger:after{
    top:55%;
    transform:translate3d(-50%, -50%, 0);
  }


  li{
    padding:1em 1em 1em 3em;
    font-size:1.2em;
  }
  .menu:focus{
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 0 100em rgba(0, 0, 0, 0.6);

  }


}

</style>