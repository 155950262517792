<template>
  <div id="app mt-0">
    <nav-bar/>
    <div class="row m-0">
      <div class="col-xl-2 p-0">
        <sidebar/>
      </div>
      <div class="col-xl-10 p-0">
        <RouterView></RouterView>
      </div>

    </div>
  </div>
</template>

<script>
import NavBar from "./navBar";
import Sidebar from "./sidebar";
export default {
  name: "Layout",
  components: {Sidebar, NavBar}
}
</script>

<style scoped>
.col-xl-2{
  width: 12%;
}
.col-xl-10{
  width: 88%;
}
</style>